import { createMuiTheme } from '@material-ui/core/styles';

const TrutzBoxTheme = createMuiTheme({
	overrides: {
		MuiSlider: {
			rail: {
				backgroundColor: '#2A2A2A',
			},
			thumb: {
				backgroundColor: '#D4301E',
			},
			track: {
				backgroundColor: '#D4301E',
			},
		},
	},
	palette: {
		primary: {
			contrastText: '#2A2A2A',
			dark: '#F0F0F0',
			light: '#FFFFFF',
			main: '#FFFFFF',
		},
		secondary: {
			contrastText: '#FFFFFF',
			dark: '#B02B18',
			light: 'rgba(212,48,30,0.05)',
			main: '#D4301E',
		},
	},
	typography: {
		fontFamily: 'Droid Sans',
	},
});

export default TrutzBoxTheme;

import { Box, CircularProgress, createStyles, makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';

interface TrutzboxCircularLoaderProps {
	hasBackground?: boolean;
	isAbsolute?: boolean;
	withShrink?: boolean;
}

const TrutzboxCircularLoader: React.FC<TrutzboxCircularLoaderProps> = (props) => {
	const useStyles = makeStyles((theme: Theme) =>
		createStyles({
			loader: {
				left: 'calc(50% - 20px)',
				position: 'relative',
				top: 'calc(50% - 20px)',
			},
			root: {
				height: '100%',
				position: 'relative',
				width: '100%',
				zIndex: theme.zIndex.drawer - 1,
			},
			rootAbsolute: {
				position: 'absolute',
			},
			rootBackground: {
					backgroundColor: theme.palette.primary.dark,
			},
		}),
	);
	const classes = useStyles();

	return (
		<Box className={clsx(classes.root, {[classes.rootBackground]: props.hasBackground,
			[classes.rootAbsolute]: props.isAbsolute})}>
			<CircularProgress className={classes.loader}
					color='secondary' disableShrink={!props.withShrink}/>
		</Box>

	);
};
export default TrutzboxCircularLoader;

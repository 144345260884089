import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import LanguageDetector from 'i18next-browser-languagedetector';

import deTranslation from './assets/i18n/de/translation.json';
import enTranslation from './assets/i18n/en/translation.json';
import nlTranslation from './assets/i18n/nl/translation.json';

const languageDetector = new LanguageDetector();

const resources = {
		de: {
				translation: deTranslation,
		},
		en: {
				translation: enTranslation,
		},
		nl: {
				translation: nlTranslation,
		},
};

/**
 * Register language change event to change lang attribute of html.
 */
i18n.on('languageChanged', (lng) =>	 {
	const htmlList = document.getElementsByTagName('html');
	for (let i = 0; i < htmlList.length; i++) {
		htmlList.item(i)!.lang = lng;
	}
});

i18n
		// load translation using xhr -> see /public/locales
		// learn more: https://github.com/i18next/i18next-xhr-backend
		// .use(backend)

		// detect user language
		// learn more: https://github.com/i18next/i18next-browser-languageDetector
		.use(languageDetector)

		// pass the i18n instance to react-i18next.
		.use(initReactI18next)
		// init i18next
		// for all options read: https://www.i18next.com/overview/configuration-options
		.init({
				debug: process.env.NODE_ENV !== 'production',
				defaultNS: 'translation',

				fallbackLng: 'de',
				load: 'languageOnly',
				resources,

				nonExplicitWhitelist: false,
				whitelist: ['de', 'en', 'nl'],

				ns: ['translation'],

				interpolation: {
						escapeValue: false, // not needed for react as it escapes by default
				},

				react: {
						wait: true,
				},
		});

export default i18n;

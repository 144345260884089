import { CssBaseline, ThemeProvider } from '@material-ui/core';
import React, { FC, Suspense, useContext, useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import ErrorFallback from './lib/component/ErrorFallback';
import TrutzboxCircularLoader from './lib/component/TrutzboxCircularLoader';
import { APIContext } from './lib/context/api';
import theme from './theme';
import getFirstBoot from './util/getFirstBoot';

/**
 * Lazy loaded admin page.
 */
const Admin = React.lazy(() => import('./Admin'));

/**
 * Lazy loaded error page.
 */
const Error = React.lazy(() => import('./page/error/Error'));

/**
 * Lazy loaded control page.
 */
const Control = React.lazy(() => import('./page/control'));

/**
 * Lazy loaded session page.
 */
const Session = React.lazy(() => import('./page/session'));

const BrowserContent: React.FC = () => {
	const location = useLocation();

	return (
		<ErrorBoundary
			fallbackRender={(props) => (
				<ErrorFallback withPaper {...props} />
			)}
			resetKeys={[location.pathname]}
		>
			<Switch>
				<Redirect exact from='/' to='/admin'/>
				<Route path='/admin' component={Admin} />
				<Route path='/error'>
					<Error />
				</Route>
				<Route path='/control'>
					<Control />
				</Route>
				<Route path='/session'>
					<Session />
				</Route>
			</Switch>
		</ErrorBoundary>
	);
};

const App: FC = () => {
	const [isFirstBoot, setFirstBoot] = useState<boolean>();
	const [error, setError] = useState<boolean>();
	const { axios } = useContext(APIContext);
	const {t} = useTranslation();

	useEffect(() => {
		getFirstBoot(axios).then((value) => {
			setFirstBoot(value);
			setError(false);
		}).catch(() => setError(true));
	}, [axios]);

	useEffect(() => {
		if (isFirstBoot === true) {
			window.location.href = '/setup';
		}
	}, [isFirstBoot]);

	if (isFirstBoot === undefined && error === undefined) {
		return <TrutzboxCircularLoader />;
	}

	if (error) {
		return (
			<>
				<CssBaseline />
				<ThemeProvider theme={theme}>
					<ErrorFallback withPaper resetErrorBoundary={() => window.location.reload()}
												 error={{name: 'Server Error', message: t('errorFallback.serverUnavailable')}}/>
				</ThemeProvider>
			</>
		);
	} else {
		return (
			<>
				<CssBaseline />
				<ThemeProvider theme={theme}>
					<BrowserRouter basename={process.env.PUBLIC_URL}>
						<Suspense fallback={<TrutzboxCircularLoader />}>
								<BrowserContent />
					</Suspense>
					</BrowserRouter>
				</ThemeProvider>
			</>
		);
	}
};

export default App;

import React, { useCallback, useState } from 'react';
import { Box, Button, Container, createStyles, Dialog, DialogActions, DialogContent, DialogTitle, Grid, makeStyles, Paper, PaperProps, Snackbar, Theme, Typography } from "@material-ui/core";
import { FallbackProps } from "react-error-boundary";
import { useTranslation } from "react-i18next";
import './ErrorFallback.css';
import clsx from 'clsx';
import copy from 'copy-to-clipboard';
import { Alert } from '@material-ui/lab';

const useStyles = makeStyles((theme: Theme) => createStyles({
	center: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	fullHeight: {
		height: '100%',
	},
	paper: {
		padding: theme.spacing(2),
	},
	messageItem: {
		width: '100%',
	},
}));

export interface ErrorFallbackProps extends FallbackProps {
	withPaper?: boolean;
}

export const ErrorFallback: React.FC<ErrorFallbackProps> = (props: ErrorFallbackProps) => {
	const {t} = useTranslation();
	const classes = useStyles();
	const [openStack, setOpenStack] = useState<boolean>(false);
	const [openSnack, setOpenSnack] = useState<boolean>(false);

	let OutlineContainer: React.ComponentType;
	let outlineContainerProps: PaperProps | undefined;
	if (props.withPaper) {
		OutlineContainer = Paper;
		outlineContainerProps = {
			elevation: 5,
			className: classes.paper,
		};
	} else {
		OutlineContainer = Box;
	}

	const handleCopy = useCallback(() => {
		if(props.error.stack) {
			if (copy(props.error.stack)) {
				setOpenSnack(true);
			}
		}
	}, [setOpenSnack, props.error.stack]);


	return (
		<Box className={clsx(classes.center, classes.fullHeight)}>
			<Box className={classes.center}>
				<OutlineContainer {...outlineContainerProps}>
					<Container maxWidth='sm'>
						<Grid container direction='column' spacing={1}>
							<Grid item>
								<Typography variant='h6'>
									{t('errorFallback.title')}
								</Typography>
							</Grid>
							<Grid item className={classes.messageItem}>
								<Typography variant='body1'>
									{t('errorFallback.message')}
								</Typography>
								<Typography variant='body2' noWrap paragraph>
								 	<b>{props.error.message}</b>
								</Typography>
							</Grid>
							{
								props.error.stack &&
								<>
								<Grid item>
									<Button
											fullWidth
											color='secondary'
											variant='contained'
											onClick={() => setOpenStack(true)}
									>
										{t('errorFallback.showStack')}
									</Button>
								</Grid>
								<Dialog open={openStack} onClose={() => setOpenStack(false)}>
									<DialogTitle>
										{t('errorFallback.stack.title')}
									</DialogTitle>
									<DialogContent>
										<pre>{props.error.stack}</pre>
									</DialogContent>
									<DialogActions>
										<Button onClick={() => setOpenStack(false)}>
											{t('misc.close')}
										</Button>
										<Button
											disabled={props.error.stack === undefined}
											color='secondary'
											onClick={handleCopy}
										>
											{t('misc.copy')}
										</Button>
									</DialogActions>
								</Dialog>
								<Snackbar open={openSnack} autoHideDuration={4000} onClose={() => setOpenSnack(false)}>
									<Alert severity='success'>
										{t('misc.copiedToClipoard')}
									</Alert>
								</Snackbar>
								</>
							}
							<Grid item>
								<Button
									fullWidth
									color='secondary'
									variant='contained'
									onClick={props.resetErrorBoundary}
								>
									{t('errorFallback.reset')}
								</Button>
							</Grid>
						</Grid>
					</Container>
				</OutlineContainer>
			</Box>
		</Box>
	);
};

export default ErrorFallback;

import React, { createContext, FC, useEffect, useMemo, useState } from 'react';
import { BrowserRouter as Router, NavLink, Route, Switch } from 'react-router-dom';

import { TextField } from '@material-ui/core';
import globalAxios, { AxiosAdapter, AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios';
import { EventEmitter } from 'events';

export const UnauthenticatedErrorEvent = Symbol('UnauthenticatedErrorEvent');
const errorStream = new EventEmitter();

const LogoutAdapter =
	(adapter: AxiosAdapter) =>
	(options: AxiosRequestConfig) =>
	adapter(options).catch((error: AxiosError) => {
		if (error.response?.status === 401) {
			errorStream.emit(UnauthenticatedErrorEvent, error);
		}

		throw error;
	});

const axiosOptions: AxiosRequestConfig = {
	adapter: LogoutAdapter(globalAxios.defaults.adapter!),
	baseURL: '/api/',
	method: 'GET',
};
const productionContextValue = {
	axios: globalAxios.create(axiosOptions),
	errors: errorStream,
};

export const APIContext = createContext<{axios: AxiosInstance, errors: EventEmitter}>(productionContextValue);
APIContext.displayName = 'APIContext';

const ProductionAPIProvider: FC = (props) => (
	<APIContext.Provider {...props} value={productionContextValue} />
);

const DevelopmentAPIProvider: FC = (props) => {
	const [baseURL, setBaseURL] = useState<string>(
		localStorage.getItem('api.baseURL') ?? '/api/',
	);
	const contextValue = useMemo<{
		axios: AxiosInstance;
		errors: EventEmitter;
	}>(() => ({ axios: globalAxios.create({ ...axiosOptions, baseURL }), errors: errorStream }), [baseURL]);
	useEffect(() => {
		localStorage.setItem('api.baseURL', baseURL);
	}, [baseURL]);

	return (
		<Router basename={process.env.PUBLIC_URL}>
			<Switch>
				<Route exact path='/dev-settings'>
					<h1>Development Settings</h1>
					<TextField
						value={baseURL}
						onChange={({target: { value }}) => setBaseURL(value)} />
						<NavLink to={'/admin'}>Done</NavLink>
				</Route>
				<Route>
					<APIContext.Provider {...props} value={contextValue} />
				</Route>
			</Switch>
		</Router>
	);
};

export const APIProvider: FC = (props) => {
	if (process.env.NODE_ENV !== 'production') {
		return <DevelopmentAPIProvider {...props} />;
	}

	return <ProductionAPIProvider {...props} />;
};

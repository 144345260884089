import { AxiosInstance } from 'axios';

/**
 * Check if it is first boot and setup is required
 */
const getFirstBoot = (
		axios: AxiosInstance,

	): Promise<boolean> => axios.get('/system/firstBoot').then(data=>data.data.isFirstBoot);

export default getFirstBoot;
